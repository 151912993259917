// images
import imgs from './images';

export const images = [
	{ original: imgs.slide_animation },
	{ original: imgs.slide_design },
	{ original: imgs.slide_development },
	{ original: imgs.slide_illustration },
	{ original: imgs.slide_voiceover },
];
