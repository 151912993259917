// Images
import imgs from '../../data/images';

export default function IllustrationSection() {
	return (
		<section id="section_illustration" className="p-4 md:p-8 bg-theme-2 flex flex-col gap-6">
			<h2 className="text-theme-text text-4xl">Illustration</h2>
			<p className="text-xl leading-8 opacity-0" data-animation="animate-fadein">
				At the core of our visual strategy, our Illustration Department excels in creating unique characters and vivid artworks that capture the imagination of young learners. This
				team of skilled artists and illustrators brings educational concepts to life, ensuring that learning is not just informative but also visually captivating and enjoyable. By
				employing various styles and techniques of drawing, they cater to a diverse range of educational topics and learning styles.
			</p>

			{/* images */}
			<div className="relative border-4">
				<div className="flex relative z-10 items-end p-3 border">
					<div>
						<img src={imgs.illustration_1} className="opacity-0" alt="" data-animation="animate-fadein-1" />
					</div>

					<div className="scale-95">
						<img src={imgs.illustration_2} className="opacity-0" alt="" data-animation="animate-fadein-2" />
					</div>

					<div className="scale-90 relative top-2">
						<img src={imgs.illustration_3} className="opacity-0" alt="" data-animation="animate-fadein-3" />
					</div>

					<div className="scale-[0.85] relative top-3">
						<img src={imgs.illustration_4} className="opacity-0" alt="" data-animation="animate-fadein-4" />
					</div>
				</div>
				<img src={imgs.illustration_bg} alt="" className="absolute top-0 h-full w-full" />
			</div>
		</section>
	);
}
