// Icons
import { FaChevronUp } from 'react-icons/fa';

export default function ToTop({ showTop }) {
	return (
		<div
			className={`fixed bottom-4 right-4 bg-theme-4 border-2 hover:bg-theme-text border-theme-text text-theme-text hover:text-theme-4 cursor-pointer opacity-40 hover:opacity-100 rounded-full w-[48px] h-[48px] flex justify-center items-center z-50 ${
				showTop ? 'scale-10' : 'scale-0'
			}`}
		>
			<a href="#">
				<FaChevronUp size={24} />
			</a>
		</div>
	);
}
