import { useEffect, useLayoutEffect, useState } from 'react';

// react-router-dom
import { useLocation } from 'react-router-dom';

// layout
import Header from './layout/Header/Header';
import Navbar from './layout/Navbar/Navbar';
import Footer from './layout/Footer/Footer';
import ToTop from './layout/ToTop/ToTop';

// Pages
import Pages from './pages';

export default function App() {
	const [showTop, setShowTop] = useState(false);
	// Start route change: detect the route has changed, then scroll to top
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		window.addEventListener('scroll', () => {
			setShowTop(window.scrollY >= 250);
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	// End route change: detect the route has changed, then scroll to top

	// Start observer: animate on-scroll
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const animation_name = entry.target.getAttribute('data-animation');
						const animation_name_arr = animation_name.split('+');
						animation_name_arr.forEach((an) => entry.target.classList.add(an));
						observer.unobserve(entry.target);
					}
				});
			},
			{
				// threshold: '0.3',
				// rootMargin: '1px',
			}
		);

		const animatedElements = document.querySelectorAll('[data-animation]');

		animatedElements.forEach((elm) => {
			observer.observe(elm);
		});

		return () => {
			observer.disconnect();
		};
	}, []);
	// End observer: animate on-scroll

	return (
		<div className="max-w-6xl m-auto overflow-x-clip text-justify">
			<Header />

			<Navbar />

			<div className="overflow-hidden">
				<Pages />
			</div>

			<Footer />

			<ToTop showTop={showTop} />
		</div>
	);
}
