import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// CSS
import './index.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
