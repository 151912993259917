import VideoSection from './VideoSection';
import AnimationSection from './AnimationSection';
import CustomersSection from './CustomersSection';
import IllustrationSection from './IllustrationSection';
import DesignSection from './DesignSection';
import VoiceoverSection from './VoiceoverSection';
import DevelopmentSection from './DevelopmentSection';
import WhatSection from './WhatSection';

export default function Home() {
	return (
		<div>
			<VideoSection />
			<IllustrationSection />
			<DesignSection />
			<AnimationSection />
			<VoiceoverSection />
			<DevelopmentSection />
			<WhatSection />
			<CustomersSection />
		</div>
	);
}
