// Images
import imgs from '../../data/images';

export default function DevelopmentSection() {
    return (
        <section id='section_development' className="flex flex-col gap-6 md:flex-row border-t-2 bg-theme-1 border-b-2 border-theme-1 p-8 md:p-16">

            <div className="flex flex-col gap-8 md:w-7/12">
                <h2 className="text-theme-text text-4xl">Development</h2>
                <p className="text-xl leading-8 relative opacity-0 left-full" data-animation="animate-slide-left">
                    At the core of our interactive services is our cutting-edge software development team. They are responsible for building robust educational platforms and applications that provide an interactive learning environment. This includes everything from user-friendly interfaces to integrated assessment tools that track and support student progress.
                </p>
            </div>


                {/* image */}
            <div className="flex flex-col gap-8 md:w-5/12">
                <div className="-mx-4 md:mx-0 md:mt-8 relative opacity-0" data-animation="animate-slide-left">
                    <img src={imgs.development} alt="" />
                </div>
            </div>

        </section>
    );
}
