// Images
import imgs from '../../data/images';

export default function AnimationSection() {
	return (
		<section id="section_animation" className="p-4 md:p-8 bg-theme-2 flex flex-col gap-6">
			<h2 className="text-theme-text text-4xl">Animation</h2>
			<p className="text-xl leading-8 relative opacity-0 left-full" data-animation="animate-slide-left">
				In an era where digital media dominates learning environments, our Animation Department plays a crucial role in transforming static educational content into dynamic,
				engaging animations. This team focuses on creating educational cartoons and motion graphics that make learning interactive and enjoyable, thereby helping students grasp
				complex subjects more easily.
			</p>

			{/* images */}
			<div>
				<div className="flex z-10 items-center">
					<div>
						<img src={imgs.animation_1} className="opacity-0" alt="" data-animation="animate-fadein-1" />
					</div>

					<div>
						<img src={imgs.animation_2} className="opacity-0" alt="" data-animation="animate-fadein-2" />
					</div>

					<div>
						<img src={imgs.animation_3} className="opacity-0" alt="" data-animation="animate-fadein-3" />
					</div>

					<div>
						<img src={imgs.animation_4} className="opacity-0" alt="" data-animation="animate-fadein-4" />
					</div>

					<div>
						<img src={imgs.animation_5} className="opacity-0" alt="" data-animation="animate-fadein-5" />
					</div>

					<div>
						<img src={imgs.animation_6} className="opacity-0" alt="" data-animation="animate-fadein-6" />
					</div>
				</div>
				<div>
					<img src={imgs.animation_bg} alt="" />
				</div>
			</div>

			{/* description */}
			<div>
				<h4 className="text-theme-text font-semibold">Rigging in Animation:</h4>
				<p className="text-sm leading-6 relative opacity-0 top-8" data-animation="animate-raise-up">
					Rigging is a fundamental step in the animation process, particularly for 3D and complex 2D animations. It involves creating the 'skeleton' or internal structure that
					allows animated characters and objects to move in realistic or stylized ways. The Rigging team at our company plays a critical role in ensuring that our educational
					animations are not only visually appealing but also accurately represent movements that are natural and engaging for educational content.
				</p>
			</div>
		</section>
	);
}
