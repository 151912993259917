// Icons
import { FaLinkedinIn } from 'react-icons/fa';
import { BsFacebook, BsWhatsapp, BsYoutube, BsMessenger } from 'react-icons/bs';

const year = new Date().getFullYear();

export default function Footer() {
	return (
		<footer className="bg-black text-white p-4 flex flex-col-reverse md:flex-row gap-4 justify-between items-center">
			{/* Social links */}
			<div className="flex gap-4 justify-center md:justify-end">
				<a href="https://m.me/100087523041707" target="_blank">
					<BsMessenger />
				</a>
				<a href="https://www.linkedin.com/company/innovation-learning-solutions/" target="_blank">
					<FaLinkedinIn />
				</a>
				<a href="https://api.whatsapp.com/send?phone=201097698461" target="_blank">
					<BsWhatsapp />
				</a>
				<a href="https://www.youtube.com/@ilsgroup-l6q" target="_blank">
					<BsYoutube />
				</a>
				<a href="https://www.facebook.com/profile.php?id=100087523041707" target="_blank">
					<BsFacebook />
				</a>
			</div>

			<a href="https://mwjb.net" rel="nofollow" className='hidden'>MWJB.NET</a>
		</footer>
	);
}
