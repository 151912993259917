import { useState } from 'react';

// Images
import imgs from '../../data/images';

export default function VideoSection() {
	const [playing, setPlaying] = useState(false);

	const video_url = 'https://www.youtube.com/embed/WkwL7VGA5Is';

	return (
		<section className="border-t-2 border-b-2 border-theme-1 p-8 md:p-16">
			{playing ? (
				<>
					<div onClick={() => setPlaying(false)} className="border animate-pulse rounded-full w-[32px] h-[32px] flex justify-center items-center bg-theme-1 text-theme-text">
						<i className="fa fa-pause"></i>
					</div>
					<iframe
						src={video_url}
						title="Tom &amp; Jerry | Tom &amp; Jerry in Full Screen | Classic Cartoon Compilation | WB Kids"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin"
						allowfullscreen
						className="w-full min-h-96"
					></iframe>
				</>
			) : (
				<div className="flex justify-center bg-theme-4">
					<img className="cursor-pointer m-auto " src={imgs.video_poster_animated} onClick={() => setPlaying(true)} />
				</div>
			)}
		</section>
	);
}
