// Pages
import Home from './Home';

import { Routes, Route } from 'react-router-dom';

export default function Pages() {
	return (
		<Routes>
			<Route path="*" element={<Home />} />
		</Routes>
	);
}
