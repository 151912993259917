// Images
import imgs from '../../data/images';

export default function DesignSection() {
	return (
		<section id="section_design" className="p-4 md:p-8 flex flex-col gap-6">
			<h2 className="text-theme-text text-4xl relative opacity-0 -top-full" data-animation="animate-slide-down">
				Design
			</h2>
			<p className="text-xl leading-8 relative opacity-0 top-full" data-animation="animate-slide-up">
				Our Educational Design Department transforms educational concepts into visually engaging layouts that facilitate effective learning. This team collaborates closely with
				educators to ensure that every design element serves a specific educational purpose, enhancing comprehension and retention for students. By incorporating various design
				methodologies, they create materials that are not only aesthetically pleasing but also functionally optimized for educational environments.
			</p>

			{/* Details */}
			<div className="flex flex-col gap-4 md:flex-row">
				{/* content */}
				<div className="flex flex-col gap-4">
					{/* Layout Design */}
					<div className="relative opacity-0 -left-full" data-animation="animate-slide-right">
						<h4 className="text-theme-text font-semibold">Layout Design:</h4>
						<p className="text-sm leading-6">
							Purpose: The layout design focuses on the organization of content on a page or screen, which is essential for guiding the learner's eye and ensuring that information
							is accessed in a logical sequence.
						</p>
					</div>

					{/* Interactive Design */}
					<div className="relative opacity-0 -left-full" data-animation="animate-slide-right">
						<h4 className="text-theme-text font-semibold">Interactive Design:</h4>
						<p className="text-sm leading-6">
							Interactive designs are crucial for digital learning platforms where engagement and interactivity enhance the learning experience. This approach includes elements
							that users can interact with, such as buttons, sliders, and interactive diagrams.
						</p>
					</div>

					{/* Educator Collaboration */}
					<div className="relative opacity-0 -left-full" data-animation="animate-slide-right">
						<h4 className="text-theme-text font-semibold">Educator Collaboration:</h4>
						<p className="text-sm leading-6">
							Designers maintain constant communication with educators to ensure that designs align with pedagogical objectives and effectively address the needs of diverse
							learning styles.
						</p>
					</div>
				</div>

				{/* image */}
				<div className="-mx-4 md:mx-0 md:mt-8 relative opacity-0" data-animation="animate-slide-left">
					<img src={imgs.design} alt="" />
				</div>
			</div>
		</section>
	);
}
