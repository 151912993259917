// Images
import { useState } from 'react';
import imgs from '../../data/images';

// Data
import { voices } from '../../data/voiceover';

export default function VoiceoverSection() {
	const [currentAudio, setCurrentAudio] = useState(null);
	const [currentAudioName, setCurrentAudioName] = useState(null);

	const AudioPlayer = ({ lang }) => {
		// Play Audio
		const playAudio = (fileName) => {
			if (currentAudio) {
				currentAudio.pause();
			}

			const audioPath = require(`../../assets/audio/${fileName}.mp3`);
			const audio = new Audio(audioPath);
			audio.play();

			// currentAudio = audio;
			setCurrentAudio(audio);
			setCurrentAudioName(fileName);
		};

		// Stop Audio
		const stopAudio = () => {
			currentAudio && currentAudio.pause();
			setCurrentAudio(null);
			setCurrentAudioName(null);
		};

		// Audio Player Component
		return (
			<div>
				<img src={currentAudioName === lang ? imgs.equalizer_on : imgs.equalizer_off} alt="" data-play={lang} />
				{/* <img src={imgs.equalizer} alt="" data-play={lang} /> */}
				<div className="flex justify-center gap-4 bg-theme-text text-black text-center hover:bg-theme-3 cursor-pointer p-2">
					{currentAudioName === lang ? (
						<span className="hover:text-theme-1 w-full" onClick={stopAudio}>
							<i className="fa fa-pause"></i>
						</span>
					) : (
						<span className="hover:text-theme-1 w-full " onClick={() => playAudio(lang)}>
							<i className="fa fa-play"></i>
						</span>
					)}
				</div>
			</div>
		);
	};

	return (
		<section id="section_voiceover" className="p-4 md:p-8 flex flex-col gap-6 md:flex-row bg-theme-4">
			{/* text */}
			<div className="flex flex-col gap-8 md:w-5/12">
				{/* Main */}
				<div>
					<h2 className="text-theme-text text-4xl">Voice-over</h2>
					<p className="text-xl leading-8 relative opacity-0 left-full" data-animation="animate-slide-left">
						With professional voice-over artists, we add an essential auditory dimension to our educational materials. Our voice talents are skilled in delivering content in a
						clear, engaging manner, enhancing the auditory learning experience for students.
					</p>
				</div>

				{/* Options */}
				<div className="flex flex-col gap-8">
					<div className="relative opacity-0 -left-full" data-animation="animate-slide-right">
						<h4 className="text-theme-text font-semibold">Synchronaization with Visuals:</h4>
						<p>
							Our team ensures that the audio perfectly syncs with visual animations or text, enhancing the overall learning experience. This synchronization is crucial for
							subjects where timing and precision matter, such as language learning or musical instruction.
						</p>
					</div>

					<div className="relative opacity-0 -left-full" data-animation="animate-slide-right">
						<h4 className="text-theme-text font-semibold">Diverse Language Offeringe:</h4>
						<p>
							To cater to a global audience, we provide voice-overs in multiple languages, which involves working with native speakers to ensure authenticity and linguistic
							accuracy.
							<br />
							Feedback and Revision: Ongoing feedback from educators and learners helps refine the voice-over content and delivery, ensuring it meets educational standards and
							learner preferences.
						</p>
					</div>
				</div>
			</div>

			{/* Media */}
			<div className="md:w-7/12 flex flex-col gap-6">
				{/* Image */}
				<div className="-mx-4 relative z-30">
					<img src={imgs.voice_over} alt="" />
				</div>

				{/* voice */}
				<div className="flex flex-wrap justify-between text-theme-text">
					{voices.map(({ lang, prefix }, i) => (
						<div key={i} className="flex flex-col items-stretch p-2 gap-3 w-1/2 md:w-1/4">
							<h2 className="p-6 bg-black flex items-center justify-center">{lang}</h2>
							<div className="flex flex-col gap-4">
								{[...Array(2)].map((_, n) => (
									<AudioPlayer key={n} lang={`${prefix}_${n + 1}`} />
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
