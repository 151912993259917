import { useState } from 'react';

// react-router-dom
import { Link, NavLink } from 'react-router-dom';

// react-icons
import { RiHome2Line, RiMenuFill } from 'react-icons/ri';

// Components
import MobileNav from '../MobileNav/MobileNav';

// Data
import { links } from '../../data/navbar';

// images
import imgs from '../../data/images';
import { FaRegFilePdf } from 'react-icons/fa';
import { IoBookOutline } from 'react-icons/io5';
import { GrCatalog } from 'react-icons/gr';

export default function Navbar() {
	const [isOpen, setIsOpen] = useState(false);
	const [showIcons, SetShowIcons] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	// Prevent vertical scrollbar while mobile menu is open
	if (isOpen) {
		document.body.classList.add('overflow-hidden');
	} else {
		document.body.classList.remove('overflow-hidden');
	}


	// Catalog
	const Catalog = () => (

		<div className='relative flex flex-col md:flex-grow md:flex-initialx gap-2 cursor-pointer'>

			<div onClick={() => SetShowIcons(!showIcons)} className='flex gap-2 items-center' >
				<GrCatalog className="text-2xl" />
				<span className={`text-theme-text text-sm ${showIcons && 'animate-zoomout'} transition-all`}>Brochure</span>
			</div>

			{
				showIcons &&
				<div className='absolute pl-4 lg:pl-8 left-full md:left-4 w-screen flex-grow flex flex-col gap-1 text-xs md:text-sm '>
					<a target='_blank' href='https://ilsgroup.net/catalog/printing/Catalog-Print.pdf' className='w-full animate-icon-download flex items-center gap-2'>
						<FaRegFilePdf />
						<span>Download <span className="hidden sm:inline">Brochure</span></span>
					</a>

					<a target='_blank' href='https://ilsgroup.net/catalog/interactive/index.html' className='w-full animate-icon-ebook flex items-center gap-2'>
						<IoBookOutline />
						<span>Interactive <span className="hidden sm:inline">Brochure</span></span>
					</a>
				</div>
			}
		</div>
	)

	return (
		<>
			<nav className='select-none'>
				{/* desktop nav */}
				<div className="hidden md:flex items-center justify-center gap-6 px-10 py-6">
					<Catalog />
					<div className="flex-1 flex justify-center items-center text-theme-text gap-8 md:gap-3 lg:gap-6">
						{links.map(({ to, label }, i) => (
							<a key={i} href={to} className="text-lg md:text-sm lg:text-lg Poppins-medium">
								{label}
							</a>
						))}
					</div>
					<a href="#">
						<RiHome2Line className="scale-125 text-2xl" />
					</a>
				</div>
			</nav>

			{/* mobile nav */}
			<nav className="sticky p-4 bg-black top-0 w-full z-50 flex md:hidden justify-center items-center transition-all">
				<div className="flex justify-between items-center cursor-pointer text-theme-gold text-4xl w-full transition-all">
					<div className={`flex ${showIcons ? 'gap-4' : 'gap-16'} transition-all`}>
						<a href="#">
							<RiHome2Line className="scale-125 text-2xl" />
						</a>

					</div>
					<div className={`${showIcons ? 'relative -ms-24' : ''}`}>
						<Catalog />
					</div>
					<RiMenuFill className="scale-x-150 text-2xl" onClick={toggleMenu} />
				</div>


				<MobileNav setIsOpen={setIsOpen} isOpen={isOpen} />
			</nav>
		</>
	);
}
