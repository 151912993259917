// react-router-dom
import { Link } from 'react-router-dom';

// Data
import { links } from '../../data/navbar';

// react-icons
import { CgClose } from 'react-icons/cg';

export default function MobileNav({ setIsOpen, isOpen }) {
	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={`bg-theme-4 fixed w-full top-0 ${isOpen ? 'left-0' : 'left-full'} text-center h-screen flex flex-col gap-16 pt-8 px-2 text-[20px] `}>
			<div className={`cursor-pointer text-theme-text relative right-5 text-3xl self-end`} onClick={toggleMenu}>
				<CgClose fontWeight={100} className="scale-120" />
			</div>

			<ul className={`flex-grow flex flex-col gap-2`}>
				{links.map(({ to, label }, i) => (
					<li key={i} className="flex flex-col justify-center items-center gap-2 w-full text-white">
						<a href={to} onClick={toggleMenu}>
							{label}
						</a>
						<div className="w-full max-w-[170px] h-[1px] bg-theme-1"></div>
					</li>
				))}
			</ul>
		</div>
	);
}
