// images
import imgs from './images';

export const images = [
  imgs.company_1,
  imgs.company_2,
  imgs.company_3,
  imgs.company_1,
  imgs.company_2,
  imgs.company_3,
  imgs.company_4,
  imgs.company_5,
  imgs.company_6,
  imgs.company_7,
  imgs.company_8,
];
