// Images
import imgs from '../../data/images';

export default function WhatSection() {
	return (
		<section className="border-t-2 bg-theme-2 border-b-2 border-theme-1 p-8 md:p-16">
			<h2 className="text-white uppercase text-3xl md:text-5xl font-extrabold border-b-4 px-2 border-theme-text relative opacity-0 -top-full" data-animation="animate-slide-down">
				What we do
			</h2>
			<div className="flex flex-col md:flex-row gap-3">
				<div className="md:w-6/12">
					<img src={imgs.what_we_do} alt="" />
				</div>
				<div className="flex flex-col gap-8 md:w-6/12 pt-4">
					<p className="text-xl leading-8 relative opacity-0 top-8" data-animation="animate-raise-up">
						ILS specializes in the production and development of comprehensive educational curricula tailored to various learning stages. Our mission is to enhance the educational
						journey by providing innovative, interactive, and visually engaging learning materials. From the initial concept of curriculum writing to the final touches of
						application development, our team is dedicated to transforming traditional educational content into captivating learning experiences.
					</p>
					<p className="text-xl leading-8 relative opacity-0 top-8" data-animation="animate-raise-up">
						ILS is committed to revolutionizing education through technology and creativity. We believe that every child deserves access to quality education that captivates their
						imagination and fosters an enduring love for learning. Join us on our journey to make education a delightful adventure for every learner.
					</p>
				</div>
			</div>
		</section>
	);
}
