// images
import { images } from '../../data/customers';

// react-fast-marquee
import Marquee from 'react-fast-marquee';

export default function CustomersSection() {
	return (
		<section id="section_animation" className="p-4 md:p-8  bg-theme-4 flex flex-col gap-6">
			<h2 className="text-white text-4xl uppercase text-center">The most important customers</h2>
			<div className="">
				<Marquee>
					{images.map((img, i) => (
						<img key={i} src={img} />
					))}
				</Marquee>
			</div>
		</section>
	);
}
