// images
import { images } from '../../data/header';
import imgs from '../../data/images';

// react-image-gallery
import ImageGallery from 'react-image-gallery';

export default function Header() {
	return (
		<header className="relative">
			{/* Carousel */}
			<div>
				<ImageGallery
					items={images}
					showFullscreenButton={false}
					showPlayButton={false}
					autoPlay={true}
					showBullets={true}
					showThumbnails={false}
					slideDuration={300}
					slideInterval={10000}
				/>
			</div>
			{/* Logo */}
			<div className="absolute top-0 left-2">
				<img src={imgs.site_logo} alt="logo" className="w-full max-w-20  md:max-w-28" />
			</div>
		</header>
	);
}
